export function resetRem () {
    let html = document.documentElement;
    if(html.clientWidth>750){
        html.style.fontSize = '75px';
    }else{
        html.style.fontSize = (html.clientWidth / 10) + 'px';
    }
    // 监听 window 的 resize 事件．在浏览器窗口变化时再设置下区域高度．
    window.onresize = () => {
        let html = document.documentElement;
        if(html.clientWidth>750){
            html.style.fontSize = '75px';
        }else{
            html.style.fontSize = (html.clientWidth / 10) + 'px';
        }
    };
 }